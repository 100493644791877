import { useState } from "react"
import Sheet from 'react-modal-sheet';

export default function SortModal(
    { isSortModalOpen, selectedSortType, onSortClick, onSortModalDismiss }
) {
    console.log(`selected sort type ${selectedSortType}`)
    return <Sheet isOpen={isSortModalOpen} onClose={() => {onSortModalDismiss()}} onOpenEnd={() => {}} detent="content-height">
        <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
        <Sheet.Scroller draggableAt="both">
        <div className="pb-4 px-4">
            <div className="font-bold text-lg py-4">Sort by</div>
            <div className="form-control" onClick={(e) => {e.preventDefault();onSortClick("last_updated");}}>
                <label className="label cursor-pointer px-0">
                    <span className="label-text text-base">Last updated</span> 
                    <input type="radio" name="radio-updated" className="radio checked:bg-primary" checked={selectedSortType == "last_updated"} />
                </label>
            </div>
            <div className="form-control" onClick={(e) => {e.preventDefault();onSortClick("last_commented")}}>
                <label className="label cursor-pointer px-0">
                    <span className="label-text text-base">Last commented</span> 
                    <input type="radio" name="radio-commented" className="radio checked:bg-primary" checked={selectedSortType == "last_commented"} />
                </label>
            </div>
            <div className="form-control">
                <label className="label cursor-pointer px-0" onClick={(e) => {e.preventDefault();onSortClick("alphabetical")}}>
                    <span className="label-text text-base">Aphabetical</span> 
                    <input type="radio" name="radio-alphabetical" className="radio checked:bg-primary" checked={selectedSortType == "alphabetical"} />
                </label>
            </div>
            <div className="form-control">
                <label className="label cursor-pointer px-0" onClick={(e) => {e.preventDefault();onSortClick("distance")}}>
                    <span className="label-text text-base">Distance from you</span> 
                    <input type="radio" name="radio-distance" className="radio checked:bg-primary" checked={selectedSortType == "distance"} />
                </label>
            </div>
        </div>
      <div className="modal-action pb-4 px-4">
        <button className="btn" onClick={
            () => {
                onSortModalDismiss()
            }
            }>Close</button>
      </div>
        </Sheet.Scroller>
        </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={(_) => {
            onSortModalDismiss()
        }}/>
    </Sheet>
}