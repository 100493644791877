import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CoorayAppBar from "../../shared/components/CoorayAppBar";
import { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import UserRepository from "../../repository/UserRepository";
import SignUpSuccessPage from "./SignUpSuccessPage";
import { logEvent } from "firebase/analytics";
import { AnalyticsEvent } from "../../shared/analytics/AnalyticsEvent";
import AppInviteCodePage from "../appinvite/AppInviteCodePage";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default function CompleteProfilePage({ analytics }) {

    const [currentPage, setCurrentPage] = useState(0)
    const [loadingText, setLoadingText] = useState(null)
    const [toastErrorMessage, setToastErrorMessage] = useState(null)
    const [handler, setHandler] = useState("")
    const [name, setName] = useState("")
    const [acceptedInviteCode, setAcceptedInviteCode] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const userRepository = new UserRepository()
  
        async function getUserSignUpStatus() {
            setLoadingText("Loading ...")
            const {
              data: { user },
            } = await supabase.auth.getUser()
            if (user == null) {
              navigate("/login", {replace: true})
            } else {
              const userSignUpStatus = await userRepository.getUserSignUpStatus()
              if (userSignUpStatus.isProfileComplete) {
                  navigate("/", {replace: true})
              } else {
                  setLoadingText(null)
              }
            }
          }
  
          getUserSignUpStatus()

          logEvent(analytics, AnalyticsEvent.COMPLETE_PROFILE_VIEW, {})
  
    }, [])



    async function saveProfile() {
        setLoadingText("Saving ...")
        const userRepository = new UserRepository()
        const response = await userRepository.updateUserProfile(handler, name, acceptedInviteCode)
        if (response.success) {
            // setCurrentPage(1)
            navigate("/", {replace: true})
        } else {
            setLoadingText(null)
            if (response.error == "HANDLER_TAKEN") {
                console.log("setting error")
                setToastErrorMessage("Sorry, this username has been taken. Please enter another one.")
            }
        }
    }

    function onHandlerChange(event) {
        setToastErrorMessage(null)
        setHandler(removeSpacesAndSpecialChars(event.target.value).toLowerCase())
    }

    function onNameChange(event) {
        setToastErrorMessage(null)
        setName(event.target.value)
    }

    function removeSpacesAndSpecialChars(inputString) {
        return inputString.replace(/[^a-zA-Z0-9]/g, '');
    }

    function onBackPressed() {
    }

    function onSaveClick() {
        if (!handler) {
            setToastErrorMessage("Username cannot be empty")
        } else if (!name) {
            setToastErrorMessage("Name cannot be empty")
        } else {
            saveProfile()
        }
    }

    function onInviteCodeAccepted(inviteCode) {
        setAcceptedInviteCode(inviteCode)
    }

    return currentPage == 0? (
        (
            acceptedInviteCode ? (
                <div>
                    <CoorayAppBar title={"👋🏼 Complete Your Profile"} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Back}/>
                    <div className="bg-base-200 px-4 py-4">
                        <div className="max-w-full text-base-content text-base">{`One more step and you're in 🙌🏼`}</div>
                    </div>
                    <div className="form-control w-full px-4 pt-4">
                        <label className="label">
                            <span className="label-text">Your username without space (e.g. thegruffalo123)</span>
                        </label>
                        <input type="text" placeholder="e.g. johndoe ..." className="input input-bordered w-full rounded-md" onChange={onHandlerChange} value={handler}/>
                    </div>
                    <div className="form-control w-full px-4 pt-4">
                        <label className="label">
                            <span className="label-text">Your name (e.g. John Doe)</span>
                        </label>
                        <input type="text" placeholder="e.g. John Doe ..." className="input input-bordered w-full rounded-md" onChange={onNameChange} value={name}/>
                    </div>
                    { toastErrorMessage && <div className="pt-4 text-sm text-error w-full px-4">{ toastErrorMessage }</div> }
                    <div className='fixed bottom-0 right-4 pb-4 w-full text-center'>
                        <button className="btn btn-success normal-case ml-4" onClick={ onSaveClick }>
                            <svg className="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path className="stroke-success-content" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H15M8 4v4a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V4M8 4h7M7 17v-3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3"/>
                            </svg>
                            Save
                        </button>
                    </div>

                    {loadingText &&
                    <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                        <div className="loading loading-infinity loading-lg"></div>
                        <div className='font-normal text-base-content mt-2'>{loadingText}</div>
                    </div>
                    }
                </div>
            ) : <AppInviteCodePage analytics={analytics} onInviteCodeAccepted={onInviteCodeAccepted} />
        )
    ): <SignUpSuccessPage name={name} />
}
