import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HomeCurationItem({ title, description, url, numberOfPlaces, numberOfContributors, imageUrl, emoji }) {
    return <a href={url}>
        <div className="card card-side rounded-none md:rounded-md bg-base-200 hover:bg-base-300 cursor-pointer h-full">
            <div className="card-body px-6 py-4 self-center">
            {imageUrl && <img className="object-cover rounded-md w-full aspect-video lg:hidden mb-2" src={imageUrl} alt=""/> }
            <h2 className="card-title font-bold text-base md:text-lg">{title}</h2>
            <div className="text-sm md:text-base">{description}</div>
            <p className="text-sm md:text-base">
                {
                    numberOfPlaces > 0 && <span className="inline-flex items-baseline pr-2 text-sm md:text-base opacity-75">
                        <svg className="w-4 h-4 self-center mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path className="stroke-base-content" d="M16 13.3744C19.5318 14.0688 22 15.6547 22 17.5C22 19.9853 17.5228 22 12 22C6.47715 22 2 19.9853 2 17.5C2 15.6547 4.46819 14.0688 8 13.3744M12 17V3L17.3177 6.27244C17.7056 6.51114 17.8995 6.63049 17.9614 6.78085C18.0154 6.912 18.0111 7.0599 17.9497 7.18771C17.8792 7.33426 17.6787 7.44222 17.2777 7.65815L12 10.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {
                            numberOfPlaces == 1 && <span>{numberOfPlaces} place</span>
                        }
                        {
                            numberOfPlaces > 1 && <span>{numberOfPlaces} places</span>
                        }
                    </span>
                }
                { numberOfPlaces > 0 && numberOfContributors > 0 && <span className="opacity-75 text-sm md:text-base"> • </span> }
                {
                    numberOfContributors > 0 && <span className="inline-flex items-baseline text-sm md:text-base opacity-75">
                        { numberOfContributors == 1 && <span>{numberOfContributors} member</span> }
                        { numberOfContributors > 1 && <span>{numberOfContributors} members</span> }
                    </span>
                }
            </p>
            </div>
            {imageUrl && <div className="hidden lg:block h-full py-4 pr-4"><LazyLoadImage className="object-cover rounded-md aspect-square h-28 self-center" src={imageUrl} alt=""/> </div>}
            {!imageUrl && emoji && <div className="h-full py-4 pr-4"><div className="text-5xl flex items-center py-4 px-4 h-24 md:h-28">{emoji}</div></div>}
        </div>
    </a>
}
