import { useState, useEffect, memo, useRef } from 'react'
import { createClient } from '@supabase/supabase-js'
import CoorayAppBar, { NavigationButtonType } from '../../shared/components/CoorayAppBar';
import { useParams, useSearchParams } from 'react-router-dom';
import CurationRepository from '../../repository/CurationRepository';
import PlaceRepository from '../../repository/PlaceRepository';
import CurationListFilterBar from '../../shared/components/CurationListFilterBar';
import CurationListItem from '../../shared/components/curationitem/CurationListItem';
import FilterModal from '../../shared/components/filtermodal/FilterModal';
import { useNavigate } from 'react-router-dom';
import PlaceDetailsPage from '../placedetails/PlaceDetailsPage';
import { CurationMap } from './CurationMap';
import SortModal from '../../shared/components/sortmodal/SortModal';
import CoorayFooter from '../../shared/components/footer/CoorayFooter';
import { logEvent } from 'firebase/analytics';
import { AnalyticsEvent } from '../../shared/analytics/AnalyticsEvent';
import { Helmet } from 'react-helmet';
import MembersPage from '../member/MembersPage';
import { usePlaceDetailsContext } from '../placedetails/PlaceDetailsContext';
import { useCurationContext } from './CurationContext';
import { useCurationPlacesContext } from './CurationPlacesContext';
import { useCurationScrollStateContext } from './CurationScrollStateContext';
import { useCurationMembersContext } from './CurationMembersContext';
import { getPlaces, getUserLocation } from './CurationListUtils';
import { useCurationMapContext } from './CurationMapContext';

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

function filtersToFilterOptionsMap(filters) {
  const filterOptionMap = {}
  filters
  .flatMap(
    (filter) => filter.filterOptions
    .map(
      (filterOption) => {
        filterOption.filter = {
          id: filter.id,
          label: filter.label
        }
        return filterOption
      }
    )
  )
  .forEach((filterOption) => {
    filterOptionMap[filterOption.id] = filterOption
  })
  return filterOptionMap
}

export default function CurationMapPage({analytics}) {
  const navigate = useNavigate()
  const topRef = useRef()
  const mapRef = useRef()
  const {_, setPlaceDetails} = usePlaceDetailsContext()
  const {placesData, setPlacesData} = useCurationPlacesContext()
  const { curationMapConfig, setCurationMapConfig } = useCurationMapContext()
  const [appBarDropdownItems, setAppBarDropdownItems] = useState([])
  const {curationMemberResponse, setCurationMemberResponse} = useCurationMembersContext()
  const {curationData, setCurationData} = useCurationContext()
  const [fullScreenLoadingText, setFullScreenLoadingText] = useState(null)
  const [isFilterBottomSheetOpen, setIsFilterBottomSheetOpen] = useState(false)
  const [isSortBottomSheetOpen, setIsSortBottomSheetOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { curationId }= useParams()

  useEffect(() => {

    const curationRepository = new CurationRepository()

    async function getCurations(curationId, filterOptions, currentFilterDistance, filterVisitOptions, currentSortType) {
      console.log(`getCurations ${curationData.curation.id} ${placesData.places.length}`)
      const {
        data: { user },
      } = await supabase.auth.getUser()
      if (user == null) {
        navigate("/login", {replace: true})
      } else {
        if (curationData.curation.id != curationId || curationData.curation.id == 0 || placesData.places.length == 0) {
          setFullScreenLoadingText("Loading ...")
          // let currentSortType = placesData.selectedSortType
            // let currentFilterDistance = placesData.selectedFilterDistance
            let coordinates = null
            if (currentSortType == "distance" || currentFilterDistance != null) {
              setFullScreenLoadingText("Fetching location ...")
              coordinates = await getUserLocation()
            }
            if (coordinates == null && currentSortType == "distance") {
              currentSortType = "last_updated"
              // setSelectedSortType("last_updated")
            } else if (coordinates == null && placesData.selectedFilterDistance != null) {
              currentFilterDistance = null
              // setSelectedSortType("last_updated")
            }

            console.log(`search params check ${placesData.selectedFilterOptions}`)
            try {
              let [curationResponse, placeList] = await Promise.all([
                curationRepository.getCurations(curationId),
                getPlaces(supabase, curationId, {}, filterOptions, currentSortType, coordinates, currentFilterDistance, filterVisitOptions)
              ]);

              setCurationData(curationResponse)
              console.log(placeList)
              setPlacesData(
                {
                  places: placeList,
                  selectedSortType: currentSortType,
                  selectedFilterOptions: filterOptions,
                  selectedFilterDistance: currentFilterDistance,
                  selectedVisitOptions: filterVisitOptions,
                }
              )
              setFullScreenLoadingText(null)
              if (user.id == curationResponse.curation.authorUserId) {
                setAppBarDropdownItems([
                  {
                    label: "Edit collection",
                    callback: () => {
                      navigate(`/collections/${curationId}/edit`)
                    }
                  },
                  {
                    label: "Delete collection",
                    callback: () => {
                      console.log("delete collection callback")
                      logEvent(analytics, AnalyticsEvent.DELETE_CURATION_CLICK, {
                        curation_id: curationId 
                      })
                      document.getElementById(`confirm-deletion-modal`).showModal()
                    }
                  }
                ])
              } else {
                setAppBarDropdownItems([])
              }
            } catch (e) {
              console.log(e.response.status)
              if (e.response.status == 403) {
                navigate("/", {replace: true})
              }
            }
        } else {
          if (user.id == curationData.curation.authorUserId) {
            setAppBarDropdownItems([
              {
                label: "Edit collection",
                link: `/collections/${curationId}/edit`
              },
              {
                label: "Delete collection",
                callback: () => {
                  console.log("delete collection callback")
                  logEvent(analytics, AnalyticsEvent.DELETE_CURATION_CLICK, {
                    curation_id: curationId 
                  })
                  document.getElementById(`confirm-deletion-modal`).showModal()
                }
              }
            ])
          } else {
            setAppBarDropdownItems([])
          }
        }
      }
    }

    async function getCurationMembers(curationId) {
      if (!curationMemberResponse || curationMemberResponse.curationId != curationId) {
        const curationRepository = new CurationRepository()
        const memberResponse = await curationRepository.getCurationMembers(curationId)
        console.log(memberResponse)
        setCurationMemberResponse({
          ...memberResponse, curationId: curationId
        })
      }
    }

    let existingFilters
    let existingFilterDistance
    const existingFilterStr = searchParams.get("filters")
    const existingDistanceFilterStr = searchParams.get("filterDistance")
    const isVisitedStr = searchParams.get("isVisited")
    const isNonVisitedStr = searchParams.get("isNonVisited")
    const sort = searchParams.get("sort")
    if (existingFilterStr) {
      existingFilters = existingFilterStr.split(",").map((filterStr) => parseInt(filterStr))
      console.log(`setting places filter ${JSON.stringify(existingFilters)}`)
    } else {
      existingFilters = placesData.selectedFilterOptions
    }

    if (existingDistanceFilterStr) {
      existingFilterDistance = parseFloat(existingDistanceFilterStr)
    } else {
      existingFilterDistance = placesData.selectedFilterDistance
    }

    const filterVisitOptions = {
      visited: isVisitedStr == "false" ? false : placesData.selectedVisitOptions.visited,
      nonVisited: isNonVisitedStr == "false" ? false : placesData.selectedVisitOptions.nonVisited
    }

    const currentSortType = sort ? sort : placesData.selectedSortType

    setPlacesData({
      ...placesData, 
      selectedFilterOptions: existingFilters, 
      selectedFilterDistance: existingFilterDistance,
      selectedVisitOptions: filterVisitOptions,
      selectedSortType: currentSortType,
    })
    console.log(`search params existing ${JSON.stringify(existingFilters)} ${existingFilterDistance}`)

    Promise.all([
      getCurations(curationId, existingFilters, existingFilterDistance, filterVisitOptions, currentSortType),
      getCurationMembers(curationId)
    ])

    logEvent(analytics, AnalyticsEvent.CURATION_VIEW, {
      curation_id: curationId
    })
  }, [curationId])

  async function deleteCuration() {
    document.getElementById(`confirm-deletion-modal`).close()
    setFullScreenLoadingText("Deleting ...")
    const curationRepository = new CurationRepository()
    await curationRepository.deleteCuration(curationId)
    navigate("/")
  }

  function handleItemClick(itemId, item) {
    const selectedFilterOptionIds = item.filterOptionIds
    console.log(`selectedFilterOptionIds ${JSON.stringify(selectedFilterOptionIds)}`)
    // curationData.filters.filter((item) => item.filterOptions.map((option) => option.id).includes())
    const placeRelevantOptions = []
    const filtersWithRelevantOptions = curationData.filters.map((filter) => {
      const filteredOptions = filter.filterOptions.filter((option) => selectedFilterOptionIds.includes(option.id))
      placeRelevantOptions.push(...filteredOptions)
      return {
        ...filter, filterOptions: filteredOptions.map((option) => option.id)
      }
    }).filter((filter) => filter.filterOptions.length > 0)

    setPlaceDetails(
      {
        ...item, filters: filtersWithRelevantOptions, filterOptions: placeRelevantOptions
      }
    )
    // console.log(`map button ref ${getComputedStyle(mapButtonRef.current).display}`)
    navigate(`/places/${itemId}`)
  }

  function handleMembersClick(position) {
    logEvent(analytics, AnalyticsEvent.CURATION_MEMBERS_CLICK, {
      curation_id: curationId,
      position: position
    })
    navigate(`/collections/${curationData.curation.id}/members`)
  }

  function handlePopupItemClick(itemId, item) {
    logEvent(analytics, AnalyticsEvent.MAP_PLACE_ROW_CLICK, {
      curation_id: curationData.curation.id,
      place_id: itemId
    })
    handleItemClick(itemId, item)
  }

  async function getPlacesAndSetState(selectedFilters, sortType, coordinates, distance, visits) {
    setFullScreenLoadingText("Loading ...")
    console.log(selectedFilters)
    const filterOptionMap = filtersToFilterOptionsMap(curationData.filters)
    const placeList = await getPlaces(supabase, curationData.curation.id, filterOptionMap, selectedFilters, sortType, coordinates, distance, visits)
    setPlacesData(
      {
        places: placeList,
        selectedSortType: sortType,
        selectedFilterOptions: selectedFilters,
        selectedFilterDistance: distance,
        selectedVisitOptions: visits
      }
    )
    setFullScreenLoadingText(null)
  }

  function onFilterButtonClick() {
    logEvent(analytics, AnalyticsEvent.FILTER_BUTTON_CLICK, {
      curation_id: curationData.curation.id
    })
    setIsFilterBottomSheetOpen(true)
  }

  function onSortButtonClick() {
    logEvent(analytics, AnalyticsEvent.SORT_BUTTON_CLICK, {
      curation_id: curationData.curation.id
    })
    document.getElementById(`sort-modal`).showModal()
  }

  function onFilterModalDismiss() {
    setIsFilterBottomSheetOpen(false)
  }

  async function onApplyFilterClick(filterOptionState, filterDistance, visitFilterState) {
    console.log(`apply filter distance ${filterDistance} ${filterOptionState}`)
    setCurationMapConfig(null)
    const filterOptionStateMap = {}
    filterOptionState.forEach((option) => {
        filterOptionStateMap[option.id] = option.isSelected
    })
    const updatedFilterOptions = placesData.selectedFilterOptions.filter(
      (filterOptionId) => !(filterOptionId in filterOptionStateMap) || filterOptionStateMap[filterOptionId]
    )
    filterOptionState.filter((option) => option.isSelected).forEach((option) => {
      if (!updatedFilterOptions.includes(option.id)) {
        updatedFilterOptions.push(option.id)
      }
    })
    let currentSortType = placesData.selectedSortType
    let coordinates = null

    logEvent(analytics, AnalyticsEvent.FILTER_APPLY_CLICK, {
      curation_id: curationData.curation.id,
      filter_distance: filterDistance
    })

    if (currentSortType == "distance" || filterDistance != null) {
      setFullScreenLoadingText("Fetching location ...")
      coordinates = await getUserLocation()
    }
    setFullScreenLoadingText(null)
    if (coordinates == null && currentSortType == "distance") {
      currentSortType = "last_updated"
      // setSelectedSortType("last_updated")
    }

    if (coordinates == null && filterDistance != null) {
      filterDistance = null
      console.log("setting existing distance to null")
    }

    setSearchParams(params => {
      console.log(`existing params ${JSON.stringify(visitFilterState)}`)
      if (updatedFilterOptions.length > 0) {
        params.set("filters", updatedFilterOptions.join());
      } else {
        params.delete("filters")
      }
      if (filterDistance) {
        params.set("filterDistance",filterDistance);
      } else {
        params.delete("filterDistance")
      }
      if (!visitFilterState.visited) {
        params.set("isVisited",false);
      } else {
        params.delete("isVisited")
      }
      if (!visitFilterState.nonVisited) {
        params.set("isNonVisited",false);
      } else {
        params.delete("isNonVisited")
      }
      return params;
    })

    console.log(updatedFilterOptions)
    setPlacesData(
      {
        places: [],
        selectedSortType: currentSortType,
        selectedFilterOptions: updatedFilterOptions,
        selectedFilterDistance: filterDistance,
        selectedVisitOptions: visitFilterState
      }
    )
    getPlacesAndSetState(updatedFilterOptions, currentSortType, coordinates, filterDistance, visitFilterState)
  }

  async function onApplySortTypeClick(sortType) {
    logEvent(analytics, AnalyticsEvent.SORT_OPTION_CLICK, {
      curation_id: curationData.curation.id,
      sort_type: sortType
    })
    document.getElementById("sort-modal").close()
    setFullScreenLoadingText("Fetching location ...")
    let coordinates = null
    if (sortType == "distance") {
      coordinates = await getUserLocation()
      if (coordinates == null) {
        sortType = "last_updated"
      }
    }
    let filterDistance = placesData.selectedFilterDistance
    if (coordinates == null && placesData.selectedFilterDistance != null) {
      filterDistance = null
    }
    setPlacesData(
      {
        places: [],
        selectedSortType: sortType,
        selectedFilterOptions: placesData.selectedFilterOptions,
        selectedFilterDistance: filterDistance,
        selectedVisitOptions: placesData.selectedVisitOptions,
      }
    )
    setFullScreenLoadingText(null)
    getPlacesAndSetState(placesData.selectedFilterOptions, sortType, coordinates, filterDistance, placesData.selectedVisitOptions)
  }

  function onBackPressed() {
    window.history.back()
  }

  function onToggleToListClick() {
    navigate(`/collections/${curationId}`)
  }

  return (
    <div className='h-screen w-screen bg-base-100'>
      <Helmet><title>{curationData.curation.title ? curationData.curation.title : "Loading ..."} | Roambear</title></Helmet>
      <div className={`flex flex-col h-screen fixed w-screen top-0`}>
        <div ref={topRef} className={`fixed md:relative ease-out ease-in duration-300 z-30 w-full md:block md:top-0 bg-base-100`}>
          <CoorayAppBar 
            shouldHideTitleInSmall={false}
            shouldHideTitleInBig={false}
            title={curationData.curation.title}
            navigationType={NavigationButtonType.Back}
            rightDropdownItems={appBarDropdownItems}
            onBackPressed={onBackPressed}
            endItems={[
              <button className="btn btn-ghost md:btn-primary" onClick={() => { 
                logEvent(analytics, AnalyticsEvent.ADD_PLACE_VIEW, {
                  curation_id: curationId,
                  position: "navbar"
                })
                navigate(`/collections/${curationData.curation.id}/add`)
              }}>
                <svg className='w-6 h-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='stroke-base-content md:stroke-primary-content' d="M4 12H20M12 4V20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className='normal-case text-base font-bold hidden md:block md:text-primary-content'>Add Place</span>
              </button>,
              <button className="btn btn-ghost" onClick={() => {
                handleMembersClick("navbar")
              } }>
              <svg className='w-6 h-6' viewBox="0 0 24 24">
                  <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
                      <g id="User" transform="translate(-480.000000, -48.000000)" fill-rule="nonzero">
                          <g id="group_fill" transform="translate(480.000000, 48.000000)">
                              <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero"></path>
                              <path className='fill-base-content' d="M13,13 C15.2091,13 17,14.7909 17,17 L17,18.5 C17,19.3284 16.3284,20 15.5,20 L3.5,20 C2.67157,20 2,19.3284 2,18.5 L2,17 C2,14.7909 3.79086,13 6,13 L13,13 Z M19,13.0002 C20.6569,13.0002 22,14.3434 22,16.0002 L22,17.5002 C22,18.3287 21.3284,19.0002 20.5,19.0002 L19,19.0002 L19,17 C19,15.3645 18.2148,13.9125 17.0008,13.0002 L19,13.0002 Z M9.5,3 C11.9853,3 14,5.01472 14,7.5 C14,9.98528 11.9853,12 9.5,12 C7.01472,12 5,9.98528 5,7.5 C5,5.01472 7.01472,3 9.5,3 Z M18,6 C19.6569,6 21,7.34315 21,9 C21,10.6569 19.6569,12 18,12 C16.3431,12 15,10.6569 15,9 C15,7.34315 16.3431,6 18,6 Z"></path>
                          </g>
                      </g>
                  </g>
              </svg>
            </button>
            ]}
          />
        </div>
        <div className='grid grid-cols-2 flex-1 overflow-y-hidden relative'>
          {
            <div className={`col-span-2 h-full max-h-screen relative order-1 md:order-last bg-base-300 pt-16 md:pt-0 max-w-full overflow-x-hidden`}>
              { placesData.places.length > 0 && <div className='relative w-full h-full'>
                  {/* {
                      curationData.curation.title && <div className={`z-20 md:hidden relative`}>
                        <CurationListFilterBar activeFilterSize={placesData.selectedFilterOptions.length + (placesData.selectedVisitOptions.visited ? 0 : 1) + (placesData.selectedVisitOptions.nonVisited ? 0 : 1) + (placesData.selectedFilterDistance == null ? 0 : 1)} onFilterButtonClick={onFilterButtonClick} onSortButtonClick={onSortButtonClick}/>
                      </div>
                  } */}
                  <CurationMap 
                    isDesktop={false} 
                    ref={mapRef}
                    mapMarkerEmoji={curationData.curation.emoji != null ? curationData.curation.emoji : "🔵"}
                    handlePopupItemClick={handlePopupItemClick}
                    onToggleBackToListClick={() => { onToggleToListClick() }}
                    analytics={analytics}
                    onFilterButtonClick={onFilterButtonClick}
                  />

              </div>
              }
            </div>
          }
        </div>
        <FilterModal 
          isFilterModalOpen={isFilterBottomSheetOpen}
          filters={curationData.filters}
          selectedFilterOptions={placesData.selectedFilterOptions}
          existingDistance={placesData.selectedFilterDistance}
          onApplyClick={onApplyFilterClick}
          onFilterModalDismiss={onFilterModalDismiss}
          visitSelections={placesData.selectedVisitOptions}
        />
        <SortModal 
          isSortModalOpen={isSortBottomSheetOpen}
          selectedSortType={placesData.selectedSortType}
          onSortClick={(type)  => {
            onApplySortTypeClick(type)
          } }
          onSortModalDismiss={() => {
            setIsSortBottomSheetOpen(false)
          }}
        />
        <dialog id="confirm-deletion-modal" className="modal">
            <div className="modal-box w-11/12 max-w-5xl">
                <h3 className="font-bold text-lg">Confirm deletion?</h3>
                <p className="py-2 text-base">Are you sure you want to delete <span className="font-bold">{curationData.curation.title}</span>?</p>
                <div className="modal-action">
                    <button className="btn btn-ghost" onClick={() => { deleteCuration() }}>Yes</button>
                    <button className="btn btn-ghost" onClick={() => { document.getElementById("confirm-deletion-modal").close() }}>Cancel</button>
                </div>
            </div>
        </dialog>

        {fullScreenLoadingText &&
          <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/70 z-50'>
              <div className="loading loading-infinity loading-lg"></div>
              <div className='font-normal text-base-content mt-2'>{ fullScreenLoadingText }</div>
          </div>}
      </div>
    </div>
    )
  }