import axios from "axios";
import { getAddPlaceUrl, getPlaceDetailsUrl, getPlacesUrl, getPlaceCommentsUrl, getUploadPlaceImageUrl, getEditPlaceUrl, getDeletePlaceUrl, getPlacePreferencesUrl, saveVisitUrl, getAddExtractedPlacesUrl, getExtractProgress, getExtractProgressUrl, getCopyPlaceUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class PlaceRepository {
    async getPlaces(curationId, filterOptions, sortType, coordinates, distance, visits) {
        const session = await supabase.auth.getSession()
        let queryParameters = []
        if (filterOptions.length > 0) {
            queryParameters.push(`filters=${filterOptions.join(",")}`)
        }
        if (sortType != null) {
            queryParameters.push(`sort=${sortType}`)
        }
        if (coordinates != null) {
            queryParameters.push(`latitude=${coordinates.latitude}`)
            queryParameters.push(`longitude=${coordinates.longitude}`)
        }
        if (distance != null) {
            queryParameters.push(`distance=${distance}`)
        }
        if (!visits.visited) {
            queryParameters.push(`hide_visited=true`)
        }
        if (!visits.nonVisited) {
            queryParameters.push(`hide_unvisited=true`)
        }

        let queryString
        if (queryParameters.length > 0) {
            queryString = `?${queryParameters.join("&")}`
        } else {
            queryString = ""
        }

        const response = await axios.get(
            `${getPlacesUrl(curationId)}${queryString}`,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async savePlace(curationId, googlePlaceId, filterOptionIds) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getAddPlaceUrl(),
            {
                curationId: curationId,
                filterOptionIds: filterOptionIds,
                googlePlaceId: googlePlaceId,
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async savePlaceWithExtractedPlaces(curationId, extractedPlaces, filterOptionIds) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getAddExtractedPlacesUrl(),
            {
                curationId: curationId,
                filterOptionIds: filterOptionIds,
                places: extractedPlaces,
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getExtractProgress(curationId, progressId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            `${getExtractProgressUrl(curationId, progressId)}`,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async updatePlace(placeId, title, googlePlaceId, filterOptionIds, description, emoji, isUsingCollectionEmoji) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getEditPlaceUrl(placeId),
            {
                title: title,
                googlePlaceId: googlePlaceId,
                filterOptionIds: filterOptionIds,
                description: description,
                emoji: emoji,
                isUsingCollectionEmoji: isUsingCollectionEmoji,
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async deletePlace(placeId) {
        const session = await supabase.auth.getSession()
        await axios.delete(
            getDeletePlaceUrl(placeId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return
    }

    async getPlaceDetails(placeId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            `${getPlaceDetailsUrl(placeId)}`,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getPlacePreferences(placeId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            `${getPlacePreferencesUrl(placeId)}`,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async savePlaceVisit(placeId) {
        const session = await supabase.auth.getSession()
        await axios.post(
            `${saveVisitUrl(placeId)}`,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async removePlaceVisit(placeId) {
        const session = await supabase.auth.getSession()
        await axios.delete(
            `${saveVisitUrl(placeId)}`,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async copyPlace(placeId, targetCurationId) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getCopyPlaceUrl(placeId),
            {
                targetCurationId: targetCurationId
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getPlaceComments(placeId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            `${getPlaceCommentsUrl(placeId)}`,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async uploadPlaceImage(placeId, imageFile) {
        const session = await supabase.auth.getSession()
        const formData = new FormData()
        formData.append("image", imageFile)
        const response = await axios.post(
            getUploadPlaceImageUrl(placeId),
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data
    }
}
