import axios from "axios";
import { getAcceptInvitationUrl, getDeclineInvitationUrl, getDeleteAccountUrl, getUpdateUserInterestsUrl, getUpdateUserProfilePictureUrl, getUpdateUserProfileUrl, getUserPendingInvitationsUrl, getUserProfileByIdUrl, getUserProfileUrl, getUserSignUpStatusUrl, getVerifyInviteCodeUrl } from "./ServiceUrls";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://oesctluxvegwtcqckcdg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9lc2N0bHV4dmVnd3RjcWNrY2RnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc5Njc3NjEsImV4cCI6MjAxMzU0Mzc2MX0.9gTKhSVxZReF3sEi5N9mpNSTEjiOEkwA8TDdijsDkZw')

export default class UserRepository {
    async getUserSignUpStatus() {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getUserSignUpStatusUrl(),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getUserProfile() {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getUserProfileUrl(),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getUserProfileById(userId) {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getUserProfileByIdUrl(userId),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async verifyInviteCode(code) {
        const session = await supabase.auth.getSession()
        try {
            const response = await axios.post(
                getVerifyInviteCodeUrl(),
                {
                    inviteCode: code
                },
                {
                    headers: {
                        'Authorization': `Bearer ${session.data.session.access_token}`
                    }
                }
            );
            return response.status
        } catch (e) {
            return e.response.status
        }
    }

    async updateUserProfile(handler, name, invitationCode) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getUpdateUserProfileUrl(),
            {
                handler: handler,
                name: name,
                inviteCode: invitationCode
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async uploadProfilePicture(picture) {
        const session = await supabase.auth.getSession()
        const formData = new FormData()
        formData.append(`image`, picture)
        const response = await axios.post(
            getUpdateUserProfilePictureUrl(),
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data
    }

    async updateUserInterests(interests) {
        const session = await supabase.auth.getSession()
        const response = await axios.post(
            getUpdateUserInterestsUrl(),
            {
                interests: interests,
            },
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async getUserPendingInvitations() {
        const session = await supabase.auth.getSession()
        const response = await axios.get(
            getUserPendingInvitationsUrl(),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
        return response.data
    }

    async acceptInvitation(invitationId) {
        const session = await supabase.auth.getSession()
        await axios.post(
            getAcceptInvitationUrl(invitationId),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async declineInvitation(invitationId) {
        const session = await supabase.auth.getSession()
        await axios.post(
            getDeclineInvitationUrl(invitationId),
            {},
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }

    async requestToDeleteAccount() {
        const session = await supabase.auth.getSession()
        console.log(`session access token is ${session.data.session.access_token}`)
        await axios.delete(
            getDeleteAccountUrl(),
            {
                headers: {
                    'Authorization': `Bearer ${session.data.session.access_token}`
                }
            }
        );
    }
}
