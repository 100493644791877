import Lottie from "lottie-react"
import lottieHi from '../../shared/lottie/lottie-hi.json';
import CoorayAppBar, { NavigationButtonType } from "../../shared/components/CoorayAppBar";
import { useNavigate } from "react-router-dom";
import FilterOptionChip from "../../shared/components/filteroptionchip/FilterOptionChip";
import { useState } from "react";
import UserRepository from "../../repository/UserRepository";

export default function SignUpSuccessPage({name}) {
    const navigate = useNavigate()
    const themeItems = [
        {
            label: "🏔️ Outdoor",
            id: "outdoor"
        },
        {
            label: "🛍️ Shopping",
            id: "shopping"
        },
        {
            label: "🌏 Travel",
            id: "travel"
        }, 
        {   label: "👶🏻 Babies",
            id: "babies"
        },
        { 
            label: "👨‍👩‍👧‍👦 Older Kids",
            id: "kids"
        }, 
        {
            label: "💎 Luxury",
            id: "luxury"
        }, {
            label: "📱 Tech",
            id: "tech"
        }, {
            label: "🏖️ Beach",
            id: "beach"
        }, {
            label: "🍪 Food",
            id: "food"
        }, {
            label: "🏸 Sports",
            id: "sports"
        }, {
            label: "🐶 Dogs",
            id: "dogs"
        }, {
            label: "☕️ Coffee",
            id: "coffee"
        }, {
            label: "🧁 Dessert",
            id: "dessert"
        }
    ]
    const [themeOptions, setThemeOptions] = useState(
        themeItems.map((item) => {
                return {
                    ...item, isSelected: false
                }    
            }
        )
    )
    const [loadingText, setLoadingText] = useState(null)

    function onBackPressed() {
        navigate(`/`, { replace: true })
    }

    function onThemeOptionClick(filterOption) {
        console.log(`${JSON.stringify(filterOption)} clicked`)
        setThemeOptions(
            themeOptions.map((option) => {
                if (option.id == filterOption.id) {
                    return {
                        ...option, isSelected: !option.isSelected
                    }
                } else {
                    return option
                }
            })
        )
    }

    async function saveProfile() {
        setLoadingText("Saving ...")
        const userRepository = new UserRepository()
        const response = await userRepository.updateUserInterests(themeOptions.filter((option) => option.isSelected).map((option) => option.id))
        navigate("/", {replace: true})
    }

    function onSkipClick(_) {
        navigate("/", {replace: true})
    }

    function onSaveClick(_) {
        saveProfile()
    }

    return (
        <div>
            <CoorayAppBar title={`You're in! 👏🏼`} onBackPressed={onBackPressed} navigationType={NavigationButtonType.Close}/>
            <div className="flex flex-col w-full items-center place-content-center">
                <Lottie className="w-56 h-56" animationData={lottieHi} loop={true} />
                <div className="font-normal text-xl text-base-content text-center pl-4 pr-4">Welcome to Cooray, <span className="font-bold">{name}</span>!</div>
                <div className="mt-4 text-center max-w-xl">
                <div className="px-4 pb-1">To better suggest relevant curations to you, please let us know if any of the following topics interest you:</div>
                {
                    themeOptions.length > 0 && <div className="px-4 py-4 gap-2 max-w-full w-full flex flex-wrap place-content-center">
                        {
                            themeOptions.map((option, index) => {
                                let endIcon
                                if (option.isSelected) {
                                    endIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path d="M4 12.6111L8.92308 17.5L20 6.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                } else {
                                    endIcon = null
                                }
                                return <div>
                                    <FilterOptionChip 
                                        key={index}
                                        chipId={index}
                                        label={option.label}
                                        isSelected={option.isSelected}
                                        endIcon={endIcon}
                                        onFilterOptionClick={() => onThemeOptionClick(option)}
                                        onEndIconClick={
                                            (_) => {}
                                        }
                                    />
                                </div>
                            })
                        }
                    </div>
                }
                </div>
                <div className="mt-4 place-self-center fixed bottom-4">
                    <button className="btn btn-ghost normal-case" onClick={onSkipClick}>Skip</button>
                    <button className="btn btn-success normal-case ml-4" onClick={onSaveClick}>Save</button>
                </div>
            </div>
            {loadingText &&
            <div className='w-full h-screen fixed top-0 flex flex-col items-center justify-center backdrop-blur-sm bg-base-100/30'>
                <div className="loading loading-infinity loading-lg"></div>
                <div className='font-normal text-base-content mt-2'>{loadingText}</div>
            </div>
            }
        </div>
    )
}
